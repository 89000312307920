<template>
	<div>
		<vue-cal :selected-date="startDate" :time-cell-height="60" :time-step="30"
			:disable-views="['years', 'year', 'month']" active-view="week" :hide-weekdays="[7]" hide-title-bar
			:events="timetables" :locale="$i18n.locale" :time-from="timeFrom" :time-to="timeTo"
			:on-event-click="showVueCalModal" />
		<CommonModal ref="vueCalModal" size="sm">
			<template v-slot:CommonModalTitle>
				<i style="font-size: 24px;" class="ri-calendar-event-line"></i>
			</template>
			<template v-slot:CommonModalContent>
				<div>
					<b-row>
						<b-col md="12" class="mb-2">{{ $moment(selectedEvent.date,
							"DD-MM-YYY").locale($i18n.locale).format('dddd') }}</b-col>
						<!-- <b-col md="12" class="mb-2">{{ selectedEvent.date }}</b-col> -->
						<b-col md="12" class="mb-2">{{ selectedEvent.title }}</b-col>
						<b-col md="12" class="mb-2">{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</b-col>
						<b-col md="12" v-html="selectedEvent.content"></b-col>
					</b-row>
				</div>
			</template>
		</CommonModal>
	</div>
</template>
<script>
// Services
import CourseRegistration from "@/services/CourseRegistration";

// Timetable
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import '/src/locales/tr/vuecal.js'
import CommonModal from "@/components/elements/CommonModal";

// Other
import moment from "moment";

export default {
	name: "CourseRegistrationTimeTable",
	components: {
		VueCal,
		CommonModal
	},
	props: {
		takenSectionIds: {
			type: Array,
			default: null
		},
		timetablesPreview: {
			type: Array,
			default: null
		},
	},
	created() {
		this.getTimeTables();
		this.startDate = moment().startOf('week').add(+1, 'days').format('YYYY-MM-DD');
	},
	data() {
		return {
			timetables: [],
			startDate: null,
			timeFrom: 8 * 60,
			timeTo: 21 * 60,
			selectedEvent: {}
		}
	},
	methods: {
		getTimeTables() {
			this.timetables = [];
			CourseRegistration.timetables(this.takenSectionIds)
				.then(response => {
					let schedule = response.data.data.schedule;
					let min = response.data.data.min;
					let max = response.data.data.max;
					schedule.forEach((dayItem) => {
						dayItem.courses.forEach((courseItem) => {
							this.timetables.push({
								start: moment(this.startDate, "YYYY-MM-DD").add(dayItem.day - 1, 'days').format('YYYY-MM-DD') + ' ' + courseItem.start_time,
								end: moment(this.startDate, "YYYY-MM-DD").add(dayItem.day - 1, 'days').format('YYYY-MM-DD') + ' ' + courseItem.end_time,
								title: courseItem.code + '-' + this.getLocaleText(courseItem, 'name'),
								content: this.getLocaleText(courseItem, 'classroom_name'),
							});
						})
					});

					if (min) {
						this.timeFrom = moment.duration(min).asMinutes();
					}
					if (max) {
						this.timeTo = moment.duration(max).asMinutes();
					}
				})

		},

		showVueCalModal(event, e) {
			this.selectedEvent = { ...event }
			this.selectedEvent.date = moment(this.selectedEvent.start).format("DD-MM-YYYY")
			this.selectedEvent.start_time = moment(this.selectedEvent.start).format("LT")
			this.selectedEvent.end_time = moment(this.selectedEvent.end).format("LT")
			this.$refs.vueCalModal.$refs.commonModal.show()
			e.preventDefault()
		}
	}
}
</script>

<style>
.vuecal {
	border: 1px solid #e9ecef;
	box-shadow: none;
}

.vuecal__menu {
	background: none;
	border-bottom: 1px solid #e9ecef;
}

.vuecal__weekdays-headings,
.vuecal__all-day {
	background: #e9ecef;
}

.vuecal__event {
	background-color: #e9ecef;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #cccccc;
}

.vuecal__cell--selected {
	background: none !important;
}

.vuecal__cell--current,
.vuecal__cell--today {
	background: none !important;
}

.vuecal__flex .weekday-label span:last-child {
	display: none;
}

.vuecal__view-btn--active {
	background: #e9ecef;
	border-bottom: 0;
}

.vuecal__event-title,
.vuecal__event-time,
.vuecal__event-content {
	font-size: 13px;
	font-weight: 500;
}

@media screen and (max-width: 992px) {

	.vuecal__event-title,
	.vuecal__event-time,
	.vuecal__event-content {
		font-size: 10px;
		font-weight: 500;
	}
}
</style>
